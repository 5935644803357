<template>
  <section class="defaultLayout">
    <TerminalLayoutHeader @help-modal="openModal" />

    <router-view />

    <HelpModal v-if="modal" @close="modal = false" />
  </section>
</template>

<script>
import { getLoginToken } from '@/core'
import HelpModal from '@/views/terminalUI/components/modals/HelpModal'
import TerminalLayoutHeader from '@/views/terminalUI/components/header/TerminalLayoutHeader'

import Api from '@/api'
export default {
  name: 'TerminalsLayout',
  components: { TerminalLayoutHeader, HelpModal },
  data() {
    return {
      modal: false,
      ping: null,
    }
  },
  mounted() {
    this.ping = setInterval(async () => {
      if (getLoginToken()) {
        try {
          await Api.terminal.sendTerminalPing()
        } catch (e) {
          console.log(e.response.data)
        }
      }
    }, 300000)
  },
  beforeDestroy() {
    clearInterval(this.ping)
  },
  methods: {
    openModal() {
      this.modal = !this.modal
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/terminalUI/terminalUI.sass'

.defaultLayout
  display: grid
  grid-template: 70px  1fr/ 1fr
  min-height: -webkit-fill-available
  min-height: 100vh
  background-color: $color-bgd-gray
</style>
