var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "defaultLayout" },
    [
      _c("TerminalLayoutHeader", { on: { "help-modal": _vm.openModal } }),
      _c("router-view"),
      _vm.modal
        ? _c("HelpModal", {
            on: {
              close: function ($event) {
                _vm.modal = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }